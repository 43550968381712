<template>
  <div class="home">
    <div>
      <b-navbar id="navbar" fixed="top">
        <b-navbar-brand id="title">isConnect Global Apps</b-navbar-brand>
      </b-navbar>
    </div>

    <div id="body-content">
      <!-- header -->
      <div id="header">
        <b-container fluid>
          <section class="header-content">
            <h1>Transform Your <br />Ideas into Powerful <br />Software</h1>
            <p>Unlock your business potential through our cutting-edge <br/> software development solutions.</p>
            <b-button size="lg" href="#section-contactus" variant="outline-danger">Get Started</b-button>
          </section>
        </b-container>
      </div>

      <!-- about -->
      <div id="section-about">
        <b-container fluid>
          <div class="content">
            <p>
              Introducing isConnect Global Apps, a leading software developer based in Bogor, Jawa Barat,
              Indonesia. We specialize in creating innovative solutions that connect businesses with their
              customers in the digital world. With our cutting-edge technology and expertise, we empower
              companies to thrive in today's competitive landscape.
            </p>
            <p>
              At isConnect Global Apps, we understand the importance of seamless user experiences and robust
              functionalities. Our talented team of developers is dedicated to crafting custom software solutions
              that cater to your unique business needs. Whether you require mobile applications, web
              development, or software integration, we deliver top-notch solutions that drive growth and
              enhance customer engagement. Trust isConnect Global Apps to transform your business digitally
              and unlock its full potential.
            </p>
          </div>
        </b-container>
      </div>

      <!-- projek -->
      <div id="section-projek">
        <b-container fluid>
          <h2>Projects</h2>
          <div class="content">            
            <b-row class="content-row">
              <b-col class="box" cols="12" sm="12" md="6" lg="4">
                <img src="../assets/img/projek01.jpeg" alt="Rounded image" />
                <h2>Custom Software Solutions</h2>
                <p>Developed bespoke software solutions tailored to the client's specific needs, resulting in increased efficiency and productivity.</p>
              </b-col>
              <b-col class="box" cols="12" sm="12" md="6" lg="4">
                <img src="../assets/img/projek02.jpeg" alt="Rounded image" />
                <h2>Web Application Development</h2>
                <p>Designed and developed web applications with intuitive user interfaces, ensuring seamless user experience and improved customer satisfaction.</p>
              </b-col>
              <b-col class="box" cols="12" sm="12" md="6" lg="4">
                <img src="../assets/img/projek03.jpeg" alt="Rounded image" />
                <h2>Mobile App Development</h2>
                <p>Created innovative and user-friendly mobile applications for Android and iOS platforms, delivering cutting-edge solutions that enhance user engagement.</p>
              </b-col>
              <b-col class="box" cols="12" sm="12" md="6" lg="4">
                <img src="../assets/img/projek04.jpeg" alt="Rounded image" />
                <h2>E-commerce Website Development</h2>
                <p>Built fully functional e-commerce websites with secure payment gateways, enabling businesses to expand their online presence and generate higher revenue.</p>
              </b-col>
              <b-col class="box" cols="12" sm="12" md="6" lg="4">
                <img src="../assets/img/projek05.jpeg" alt="Rounded image" />
                <h2>Software Testing and Quality Assurance</h2>
                <p>Conducted comprehensive testing and quality assurance measures to ensure the reliability and performance of software products, reducing bugs and enhancing user experience.</p>
              </b-col>
              <b-col class="box" cols="12" sm="12" md="6" lg="4">
                <img src="../assets/img/projek06.jpeg" alt="Rounded image" />
                <h2>Software Maintenance and Support</h2>
                <p>Provided ongoing maintenance and support services, including regular updates and bug fixes, to ensure the smooth operation and longevity of software applications.</p>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>

      <!-- Our Clients -->
      <div id="section-client">
        <b-container fluid>
          <div class="content">
            <h2>Our Clients</h2>
            <b-row class="content-row">
              <b-col class="box" cols="12" sm="12" md="6" lg="4">
                <img src="../assets/img/client01.jpeg" alt="Rounded image" />
                <h2>Corporate</h2>
              </b-col>
              <b-col class="box" cols="12" sm="12" md="6" lg="4">
                <img src="../assets/img/client02.jpeg" alt="Rounded image" />
                <h2>StartUp</h2>
              </b-col>
              <b-col class="box" cols="12" sm="12" md="6" lg="4">
                <img src="../assets/img/client03.jpeg" alt="Rounded image" />
                <h2>Goverment</h2>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>

      <!-- About Us -->
      <div id="section-aboutus">
        <b-container fluid>
          <div class="content">
            <b-row class="content-row">
              <b-col class="box" id="aboutus" cols="12" sm="12" md="12" lg="6">
                <h2>About us</h2>
                <p>
                  isConnect Global Apps is a leading software developer based in Bogor, Jawa Barat, Indonesia. With our expertise and dedication, we strive to provide innovative solutions that meet the unique needs of businesses across
                  various industries.
                </p>
                <p>
                  At isConnect Global Apps, we are committed to delivering high-quality software that enhances efficiency, productivity, and growth. Our team of skilled developers and designers work closely with clients to understand their
                  requirements and develop tailor-made solutions that drive success. Trust us to transform your ideas into reality and take your business to new heights.
                </p>
              </b-col>
              <b-col class="box" id="imageabout" cols="12" sm="12" md="12" lg="6">
                <img src="../assets/img/aboutus.jpeg" alt="Rounded image" />
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>

      <!-- Contact Us -->
      <div id="section-contactus">
        <b-container fluid>
          <div class="content">
            <b-row class="content-row">
              <b-col class="box" cols="12" sm="12" md="12" lg="6">
                <h2>Contact us</h2>
                <p>Complete the following form to contact us. We are here to help and answer any questions you have about our software development services.</p>
                <p>You can also send an email to <span class="text-primary">amar@isconnect.top</span></p>
              </b-col>
              <b-col class="box" cols="12" sm="12" md="12" lg="6">
                <div id="formcontact">
                  <b-row id="inputarea">
                    <b-col cols="12" sm="12" md="12" lg="6">
                      <div>
                        <label for="username">Name</label>
                        <b-form-input v-model="name" type="text" id="username" name="username" class="textform" autocomplete="off"></b-form-input>
                      </div>
                    </b-col>
                    <b-col cols="12" sm="12" md="12" lg="6">
                      <div>
                        <label for="useremail">E-Mail</label>
                        <b-form-input v-model="email" type="email" id="useremail" name="useremail" class="textform"></b-form-input>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row id="messagearea">
                    <b-col cols="12" sm="12" md="12" lg="12">
                      <div>
                        <label for="usermessage">Message</label>
                        <b-form-textarea v-model="message" class="textform" id="usermessage" name="usermessage" rows="5" max-rows="6"></b-form-textarea>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row id="submitarea">
                    <b-col cols="12" sm="12" md="8" lg="8">
                      <p>
                        This site is protected by reCAPTCHA and the Google
                        <span><a href="https://policies.google.com/privacy">Privacy Policy</a></span> and <span><a href="https://policies.google.com/terms">Terms of Service</a></span> apply.
                      </p>
                    </b-col>
                    <b-col cols="12" sm="12" md="4" lg="4">
                      <b-button @click="submitButton" id="submitbtn" type="button" variant="warning">Send</b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>

      <!-- Contact Us -->
      <div id="section-footer">
        <b-container fluid>
          <div class="content">
            <b-row class="content-row">
              <b-col class="box" cols="12" sm="12" md="8" lg="8">
                <p id="author">&copy; isConnect Global Apps</p>
              </b-col>
              <b-col class="box d-sm-none d-md-block" cols="12" sm="12" md="4" lg="4">
                <p class="text-right" id="madein">2023 - Made with Love</p>
              </b-col>
            </b-row>
          </div>
      </b-container>
      </div>
    </div>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
  </div>
</template>

<script src="https://unpkg.com/typed.js@2.1.0/dist/typed.umd.js"></script>
<script>
  import Swal from 'sweetalert2';
  import axios from 'axios';

  export default {
    name: "HomeView",
    data() {
      return {
        name: '',
        email: '',
        message: ''
      }
    },
    methods: {
      resetForm(){
        this.name    = '';
        this.email   = '';
        this.message = '';
      },
      sendNotif(message){
        let pesan   = message;
        let channel = 'is';
        // url notif
        let uri_iga = `https://faas-sgp1-18bc02ac.doserverless.co/api/v1/web/fn-c5fd4c13-ade4-45bd-91fc-1d7aa2a1b261/slacknotif/hello?ch=${channel}&pesan=${pesan}`;
        axios.get(uri_iga)
        .then((response) => {
          console.log(response.status);
        }).catch((error) => {
          console.log(error);
        });
      },
      submitButton() {
        const self = this;

        if(this.name === '' || this.email === '' || this.message === ''){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please complete the form correctly"
          });
          return;
        }

        const url_formsubmit = 'https://colabs.isconnect.top';
        // const url_formsubmit = 'http://127.0.0.1:8787';
        const url_formsubmit_reverse = `${url_formsubmit}?name=${this.name}&email=${this.email}&message=${this.message}`;
        axios.get(url_formsubmit_reverse)
        .then((response) => {
          console.log(response.status);
          Swal.fire({
            icon: "success",
            title: "Succeed",
            text: "The form has been successfully submitted"
          });
          // send slack notif
          let param_slack = `${this.name} - ${this.email} : ${this.message}`
          self.sendNotif(param_slack);

          // reset form
          self.resetForm();
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Data was not sent successfully"
          });

          // reset form
          self.resetForm();
        });
      }
    }
  }
</script>