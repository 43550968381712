<template>
  <div class="error404">
    <b-container>
      <img id="error" src="../assets/img/error404.svg" alt="error404">
      <h2>Error 404</h2>
      <p>The page you are looking for is not found or is currently under development.</p>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>
<style scoped>
.error404 {
  margin-top: 8rem;
  text-align: center;
}
.error404 img#error {
  width: 500px;
  margin-bottom: 25px;
}
</style>